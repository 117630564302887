export default function LoadingSplash() {
  return (
    <div className="position-fixed" style={{width: "100%", height: "100%"}}>
      <div style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)"}}>
        <svg fill="none" height="80" viewBox="0 0 80 80" width="80" xmlns="http://www.w3.org/2000/svg">
          <rect fill="#2CC360" height="80" rx="40" width="80"/>
          <path
            clipRule="evenodd"
            d="M25 35C25 29.4772 29.4772 25 35 25L45 35V25H55V45C55 50.5228 50.5228 55 45 55L35 45V55H25V35Z"
            fill="white"
            fillRule="evenodd"
          />
        </svg>
      </div>
    </div>
  )
}
