const abilitiesToLoad = [
  ["admin", ["access"]],
  ["admin_layout_menu", [
    "activities",
    "bundles",
    "callLists",
    "comments",
    "competitions",
    "contacts",
    "contactGroups",
    "conversations",
    "subjects",
    "economy",
    "highscores",
    "logs",
    "navigations",
    "orders",
    "organizations",
    "suppliers",
    "photoColors",
    "photoQualities",
    "photos",
    "photoProducts",
    "photoSessions",
    "photoSheetProducts",
    "photoTypes",
    "plans",
    "reimbursements",
    "roles",
    "sheetLayouts",
    "sheetSizes",
    "surveys",
    "tags",
    "teams",
    "teamSurveys",
    "tenants",
    "tenantPhotoProducts",
    "users",
    "webshop"
  ]],
  [BankAccount, ["updateAdminData"]],
  [CallList, ["createFromWorkplace"]],
  [Tag, ["workplaceMerge"]]
]

export default abilitiesToLoad
