import "controllers"
import {BrowserRouter, useRoutes} from "react-router-dom"
import React, {Suspense} from "react"
import abilitiesToLoad from "../admin/abilities-to-load"
import AnimatedTransition from "components/animated-transition"
import {App} from "../components/app"
import {CanCanApp} from "shared/can-can-app"
import FlashMessages from "nemoa/components/flash-messages"
import LoadingSplash from "components/loading-splash"
import ReactDOM from "react-dom"
import {readRoutes} from "shared/resource-routes"
import ScrollToTop from "shared/scroll-to-top"

const requireComponent = (component) => React.lazy(() => import(/* webpackChunkName: "[request]" */ `admin/routes/${component}`))

function RouteDefinitions() {
  const routes = readRoutes({
    requireComponent,
    routeDefinitions: require("admin/route-definitions.json")
  })

  return useRoutes(routes)
}

require("stylesheets/application")
require("stylesheets/contained-image")
require("shared/devise")
require("shared/error-logger")
require("shared/i18n")
require("shared/session-status-updater")

document.addEventListener("DOMContentLoaded", () => {
  const reactRoot = document.querySelector(".react-root")

  ReactDOM.render(
    (
      <BrowserRouter>
        <Suspense fallback={<LoadingSplash />}>
          <App>
            <ScrollToTop />
            <FlashMessages />
            <AnimatedTransition>
              <CanCanApp abilities={abilitiesToLoad}>
                <RouteDefinitions />
              </CanCanApp>
            </AnimatedTransition>
          </App>
        </Suspense>
      </BrowserRouter>
    ),
    reactRoot
  )
})
